/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import contactDec from "../assets/images/contact-dec.png";
import contactLeftDec from "../assets/images/contact-left-dec.png";

interface ContactProps { }

interface ContactState { }

class Contact extends React.Component<ContactProps, ContactState> {
  state = {};
  render() {
    return (
      <>
        <div id="contact" className="contact-us section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="section-heading">
                  <h2>
                    Feel free to <em>Get an instant quote</em> for your shipment
                  </h2>
                  <div id="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3016.675479042093!2d29.261387815838077!3d40.87899547931414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadb627b831d75%3A0x8d3f6eeb9d217c4c!2sFevzi%20%C3%87akmak%2C%20Zonguldak%20Sk.%2022%20A%2C%2034899%20Pendik%2F%C4%B0stanbul!5e0!3m2!1str!2str!4v1643764066359!5m2!1str!2str"
                      width="100%"
                      height="360px"
                      frameBorder="0"
                      style={{ border: 0 }}
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="info">
                    <span>
                      <a href="#">
                        <i className="fa fa-phone" style={{marginLeft: "0"}}></i>+90 216 969 95 79
                      </a>
                      <br />
                      <a href="#">
                        <i className="fa fa-whatsapp" style={{background: "green"}}></i>+90 536 612 38 08
                      </a>
                    </span>
                    <span>
                      <i className="fa fa-envelope" style={{marginLeft: "0"}}></i>{" "}
                      <a href="#">
                        info@han-line.com
                        <br />
                        hanlinelogistics@gmail.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 align-self-center">
                <form id="contact" action="" method="get">
                  <div className="row">
                    <div className="col-lg-12">
                      <fieldset>
                        <input
                          type="name"
                          name="name"
                          id="name"
                          placeholder="Name"
                          autoComplete="on"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <input
                          type="surname"
                          name="surname"
                          id="surname"
                          placeholder="Surname"
                          autoComplete="on"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          placeholder="Your Email"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <input
                          type="text"
                          name="website"
                          id="website"
                          placeholder="Your Website URL"
                          required
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <button
                          type="submit"
                          id="form-submit"
                          className="main-button"
                        >
                          Submit Request
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="contact-dec">
            <img src={contactDec} alt="" />
          </div>
          <div className="contact-left-dec">
            <img src={contactLeftDec} alt="" />
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
