import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import servicesLeftDec from "../../assets/images/services-left-dec.png";
import servicesRightDec from "../../assets/images/services-right-dec.png";
import Service from "./Service";


import ocean from "../../assets/images/ocean_freight.jpeg";
import road from "../../assets/images/road_freight.jpeg";
import rail from "../../assets/images/rail_freight.jpg";
import air from "../../assets/images/air_freight.jpg";
import custom from "../../assets/images/customs_clearance.png";
import warehousing from "../../assets/images/warehousing.jpg";
interface ServicesProps { }

interface ServicesState { }

class Services extends React.Component<ServicesProps, ServicesState> {
  services = [
    {
      title: "Sea Freight",
      message:
        "With our wide and powerful network of agencies, we offer seafreight services with regular, weekly depart plans in all national and international ports. We provide LCL container and FCL container transportation services in up to 5000 ports around the world and up to 100 global scale ports.  Our company collects your container from your door and safely delivers it to your customer’s door providing privileged service and cost advantages thanks to its operation offices nationwide. FCL (Full Container Load) and LCL (Less than Container Load) Door-to-door Container Transport",
      image: ocean,
    },
    {
      title: "Road Freight",
      message:
        "HAN – LINE LOGISTICS CO LTD offers all-inclusive, safe, economical and reliable Road Freight services running across the global platform with a team of experts, innovative fleet and well-established network across the world. With its comprehensive, highly dependable web-based tracking system, HAN – LINE LOGISTICS CO LTD provides Door to Door visibility in both Domestic and International landscapes. HAN – LINE LOGISTICS CO LTD Road Freight services cover all needs of customers whether it's full truck load, less than truck load, groupage, domestic and international transport, oversized cargo, as well as temperature controlled or dangerous goods transport.",
      image: road,
    },
    {
      title: "Rail Freight",
      message:
        "Rail transport is an environmentally friendly transport module as well as many advantages in terms of cost and transit time. HAN – LINE LOGISTICS CO LTD offers the most economical and appropriate solutions for your intermodal and project-based rail freights to Europe and CIS countries. Rail transport aside from being an environmentally friendly transportation module, has many advantages in terms of cost and transit time. HAN – LINE LOGISTICS CO LTD offers the most economic and suitable solutions for your intermodal and project-based rail freights to Europe and CIS countries.",
      image: rail,
    },
    {
      title: "Air Freight",
      message:
        "Our company HAN – LINE LOGISTICS CO LTD, which is a founding member of WIN (World Independent Network), an international network with 280 offices and 6405 employees in 72 countries in 5 continents, cooperates with all airline companies in the world thanks to the IATA and FIATA certificates. We fly to each point in the world… With our wide network of agencies that covers the entire world, our qualified staff and robust infrastructure, HAN – LINE LOGISTICS CO LTD fully provides consolidated, express, door-to-door, export transportation, complete transportation and multi-modal transportation services. Acting based on the philosophy of being a strategic business partner for our customers, we provide aircraft charter, 3rd country transit transportation, degradable and hazardous goods transportation and contract services with space guarantee with ship owners through our worldwide active powerful network.",
      image: air,
    },
    {
      title: "Customs Clearance/Transit Shipments",
      message:
        "Customs clearance, T1 and export documentation are daily jobs, besides these services we offer certificates of origin, splitting documents, temporary clearance, fiscal clearance(s) and more.",
      image: custom,
    },
    {
      title: "Warehousing & Distribution Services",
      message:
        "We offer warehousing & distribution services from modern, well organised and secure units in Istanbul. We are able to unload your cargo, offer full picking and packing services as well as offering delivery to your global network of clients. With access to online stock records, we can provide all you need to coordinate your cargo throughout Europe and beyond.",
      image: warehousing,
    },
  ];

  state = {};
  render() {
    return (
      <>
        <div id="services" className="our-services section">
          <div className="services-right-dec">
            <img src={servicesRightDec} alt="" />
          </div>
          <div className="container">
            <div className="services-left-dec">
              <img src={servicesLeftDec} alt="" />
            </div>
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="section-heading">
                  <h2>
                    We <em>Provide</em> The Best Services With{" "}
                    <span>Our Experience</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <OwlCarousel
                  className="owl-theme owl-carousel owl-services"
                  loop
                  margin={5}
                  autoplay={true}
                  nav={true}
                  items={4}
                  dots={false}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                  }}
                >
                  {this.services.map((k) => (
                    <Service
                      image={k.image}
                      title={k.title}
                      message={k.message}
                    />
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services;
