import React from "react";

import aboutLeftImage from "../assets/images/about-left-image.png";
import logo from "../assets/images/logo.png";
interface AboutProps {}

interface AboutState {}

class About extends React.Component<AboutProps, AboutState> {
  state = {};
  render() {
    return (
      <>
        <div id="about" className="about-us section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="left-image">
                  <img style={{width: "400px", height: "auto"}} src={logo} alt="Two Girls working together" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-heading">
                  <h2>
                    Who <em>are</em> we?
                  </h2>
                  <p>
                    HAN – LINE LOGISTICS CO LTD were established in Istanbul,
                    TURKEY in 2006 to provide a comprehensive shipping and
                    freight forwarding service. Our dedicated and experienced
                    team provide a competitive one-stop solution for all your
                    import and export requirements, whatever the routing and
                    mode of transport. With competitive shipping rates and a
                    real emphasis on personal service, we are ideally placed to
                    help you and your business with all your shipping
                    requirements, be it by sea, air , road or rail . We
                    understand the need for flexibility and tailor our services
                    to the specific needs of the customer. We can assist whether
                    you need us to help source the most competitive worldwide
                    freight, or whether you just require us to customs clear
                    your goods and arrange transport to your designated delivery
                    point.
                  </p>
                  <p>
                    We are prepared to go the extra mile to assist you and this
                    hands-on approach has enabled us to form excellent long-term
                    relations with our customers. We pride ourselves on becoming
                    a key part of their supply team. Please contact us if we can
                    assist you or see our online quotation system if you require
                    a shipping rate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
