import React from "react";

interface TrackingProps {}

interface TrackingState {}

class Tracking extends React.Component<TrackingProps, TrackingState> {
  state = {};
  render() {
    return (
      <>
      <div id="tracking" style={{marginBottom: 30}}></div>
        <div  className="subscribe">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-content">
                  <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                      <h2>Online Tracking</h2>
                      <form id="subscribe" action="" method="get">
                        <input
                          type="text"
                          name="website"
                          id="website"
                          placeholder="B/L Number"
                          required
                        />
                        <input
                          type="text"
                          name="email"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          placeholder="Your Email"
                          required
                        />
                        <button
                          type="submit"
                          id="form-submit"
                          className="main-button "
                        >
                          Track
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Tracking;
