import React from "react";
import Logo from "../assets/images/logo.png";
import BgImage from "../assets/images/header_bg.jpeg";
interface HeaderProps { }

interface HeaderState { }

class Header extends React.Component<HeaderProps, HeaderState> {
  state = {};
  render() {
    return (
      <>
        <header
          className="header-area header-sticky wow slideInDown"
          data-wow-duration="0.75s"
          data-wow-delay="0s"
          style={{
            background: "linear-gradient(rgba(255,255,255,0.7),rgba(255,255,255,0.7)), Url('" + BgImage + "')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto",
            backgroundColor: "rgba(255,255,255,1)",
            backgroundPosition: "350px 0px",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="main-nav">
                  <a href="index.html" className="logo" style={{width: "400px", marginLeft:"-175px"}}>
                    <img
                      src={Logo}
                      alt="Han-Line"
                      style={{ width: 120, height: "auto", marginTop: "20px", marginRight: "40px", marginLeft: "0px", float: "left" }}
                    />
                    <div style={{ color: "#000000", lineHeight: "30px", marginTop: "40px" }}>
                      <p style={{fontSize: "27px", color: "#103879", fontFamily:"'Poppins', sans-serif"}}>Han-Line</p>
                    </div>
                  </a>

                  <ul className="nav">
                    <li className="scroll-to-section">
                      <a href="#top" className="active">
                        Home
                      </a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#services">Services</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#about">About</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#tracking">Tracking</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#contact">Contact Us</a>
                    </li>
                    <li className="scroll-to-section">
                      <div className="main-red-button-hover">
                        <a href="#contact">Contact Us Now</a>
                      </div>
                    </li>
                  </ul>
                  <a className="menu-trigger">
                    <span>Menu</span>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
