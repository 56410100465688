import React from "react";

import footerDec from "../assets/images/footer-dec.png";
import logo from "../assets/images/logo.png";

interface FooterProps {}

interface FooterState {}

class Footer extends React.Component<FooterProps, FooterState> {
  state = {};
  render() {
    return (
      <>
        <div className="footer-dec">
          <img src={footerDec} alt="" />
        </div>

        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="about footer-item">
                  <div className="logo">
                    <a href="#">
                      <img
                        src={logo}
                        alt="HAN – LINE LOGISTICS CO LTD"
                      />
                    </a>
                  </div>
                  <a href="#">HAN – LINE LOGISTICS CO LTD</a>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="services footer-item">
                  <h4>Services</h4>
                  <ul>
                    <li>
                      <a href="#services">Sea Freight</a>
                    </li>
                    <li>
                      <a href="#services">Road Freight</a>
                    </li>
                    <li>
                      <a href="#services">Air Freight</a>
                    </li>
                    <li>
                      <a href="#services">Rail Freight</a>
                    </li>
                    <li>
                      <a href="#services">Customs Clearance/Transit Shipments</a>
                    </li>
                    <li>
                      <a href="#services">Warehousing & Distribution Services</a>
                    </li>
                  </ul>
                </div>
              </div>
              
              {/* <div className="col-lg-3">
                <div className="subscribe-newsletters footer-item">
                  <h4>Subscribe Newsletters</h4>
                  <p>Get our latest news and ideas to your inbox</p>
                  <form action="#" method="get">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      pattern="[^ @]*@[^ @]*"
                      placeholder="Your Email"
                      //   required=""
                    />
                    <button
                      type="submit"
                      id="form-submit"
                      className="main-button "
                    >
                      <i className="fa fa-paper-plane-o"></i>
                    </button>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
