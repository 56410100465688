/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import mainBannerRight1 from "../assets/images/banner-right-image1.png";
import mainBannerRight2 from "../assets/images/banner-right-image2.png";

interface MainBannerProps {}

interface MainBannerState {}

class MainBanner extends React.Component<MainBannerProps, MainBannerState> {
  state = {};

  componentDidMount() {
    this.preloadImages();
  }
  preloadImages() {
    var image1 = new Image();
    image1.src = mainBannerRight1;

    var image2 = new Image();
    image2.src = mainBannerRight2;
  }

  render() {
    return (
      <>
        <div className="main-banner first" id="top" style={{ height: 800, marginTop: "60px" }}>
          <div className="container" style={{marginTop: "200px"}}>
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6 align-self-center">
                    <OwlCarousel
                      className="owl-theme owl-carousel owl-banner"
                      loop
                      margin={0}
                      autoplay={true}
                      nav={false}
                      items={1}
                      dots={false}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        600: {
                          items: 1,
                        },
                        1000: {
                          items: 1,
                        },
                        1600: {
                          items: 1,
                        },
                      }}
                      onChange={(e) => {
                        $(".main-banner").removeClass("first");
                        $(".main-banner").removeClass("second");
                        $(".main-banner").removeClass("third");

                        switch (e.item.index) {
                          case 0:
                            $(".main-banner").addClass("first");
                            break;
                          case 1:
                            $(".main-banner").addClass("first");
                            break;
                          case 2:
                            $(".main-banner").addClass("second");
                            break;
                          case 3:
                            $(".main-banner").addClass("third");
                            break;
                        }
                      }}
                    >
                      <div className="item header-text">
                        <h6>Welcome to Han-Line</h6>
                        <h2>
                          Connect <em>your business </em> with the world
                        </h2>
                        <p>
                          Whatever your industry, we are your global freight
                          forwarder
                        </p>
                        <div className="down-buttons">
                          <div className="main-blue-button-hover">
                            <a href="#contact">Message Us Now</a>
                          </div>
                          <div className="call-button">
                            <a href="https://api.whatsapp.com/send/?phone=00905366123808&text=Han-Line+Logistics&app_absent=0&lang=en">
                              <i className="fa fa-whatsapp"></i> 00 90 0536 612
                              3808
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="item header-text">
                        <h6>Expert Logistics</h6>
                        <h2>
                          Get the <em>best services</em> for{" "}
                          <span>your goods</span>
                        </h2>
                        <p>
                          We have been providing critical logistics solutions
                          for customers for over 20 years.
                        </p>
                        <div className="down-buttons">
                          <div className="main-blue-button-hover">
                            <a href="#services">Our Services</a>
                          </div>
                          <div className="call-button">
                            <a href="https://api.whatsapp.com/send/?phone=00905366123808&text=Han-Line+Logistics&app_absent=0&lang=en">
                              <i className="fa fa-whatsapp"></i> 00 90 0536 612
                              3808
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="item header-text">
                        <h6>Quote from an expert</h6>
                        <h2>
                          Work with people you can <em>rely on.</em>
                        </h2>
                        <p>
                          Connect with our supply chain experts to find
                          solutions for your freight and logistics needs.
                        </p>
                        <div className="down-buttons">
                          <div className="main-blue-button-hover">
                            <a href="#services">Our Services</a>
                          </div>
                          <div className="call-button">
                            <a href="https://api.whatsapp.com/send/?phone=00905366123808&text=Han-Line+Logistics&app_absent=0&lang=en">
                              <i className="fa fa-whatsapp"></i> 00 90 0536 612
                              3808
                            </a>
                          </div>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MainBanner;
