import React from "react";

interface ServiceProps {
    title: string;
    image: string;
    message: string;
}

interface ServiceState {}

class Service extends React.Component<ServiceProps, ServiceState> {
  state = {};
  render() {
    return (
      <>
        <div className="item" style={{background: "linear-gradient(rgba(255,255,255,0.7),rgba(255,255,255,0.7)), Url('" +this.props.image+"')"}}>
          <h4 style={{color: "#103879"}}>{this.props.title}</h4>
          <p style={{fontWeight:"bolder"}}>{this.props.message}</p>
        </div>
      </>
    );
  }
}

export default Service;
