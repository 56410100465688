import React from "react";
import Header from "../Components/Header";
import MainBanner from "../Components/MainBanner";
import Services from "../Components/Services/Services";
import Footer from "../Components/Footer";
import Preloader from "../Components/Preloader";
import Contact from "../Components/Contact";
import About from "../Components/About";
import Tracking from "../Components/Tracking";

interface LayoutProps {}

interface LayoutState {}

class Layout extends React.Component<LayoutProps, LayoutState> {
  componentDidMount() {
    $(document).on("click", ".naccs .menu div", function () {
      var numberIndex = $(this).index();

      if (!$(this).is("active")) {
        $(".naccs .menu div").removeClass("active");
        $(".naccs ul li").removeClass("active");

        $(this).addClass("active");
        $(".naccs ul")
          .find("li:eq(" + numberIndex + ")")
          .addClass("active");

        var listItemHeight = $(".naccs ul")
          .find("li:eq(" + numberIndex + ")")
          .innerHeight();
        $(".naccs ul").height(listItemHeight + "px");
      }
    });
  }
  state = {};
  render() {
    return (
      <>
        <Preloader />
        <Header />
        <MainBanner />
        <Services />
        <About />
        <Tracking />
        <Contact />
        <Footer />
      </>
    );
  }
}

export default Layout;
