import React from "react";

interface PreLoaderProps {}

interface PreLoaderState {}

class PreLoader extends React.Component<PreLoaderProps, PreLoaderState> {
  state = {};
  render() {
    return (
      <div id="js-preloader" className="js-preloader">
        <div className="preloader-inner">
          <span className="dot"></span>
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    );
  }
}

export default PreLoader;
